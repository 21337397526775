const $ = require('jquery');
global.$ = global.jQuery = $;
import whatInput from 'what-input';
import foundation from 'foundation-sites';
import intlTelInput from 'intl-tel-input';
import 'simplelightbox/dist/simple-lightbox.min.js';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {tns} from "tiny-slider/src/tiny-slider";
import 'jquery.fancytable/dist/fancyTable.min';

whatInput.hasOwnProperty('keep-this');
foundation.hasOwnProperty('keep-this');

$(document).foundation();
$(document).ready(function(){

    // To Scroll Top
    $("#oto-back-to-top").hide();
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                $('#oto-back-to-top').fadeIn();
            } else {
                $('#oto-back-to-top').fadeOut();
            }
        });
        // scroll body to 0px on click
        $('#oto-back-to-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });

    // Phone pure view
    const phoneField = document.querySelector('input[type="tel"]');
    if (phoneField !== null){
        let phoneName = phoneField.name;
        let iti = intlTelInput(phoneField, {
            nationalMode: true,
            countrySearch: false,
            preferredCountries: ['bg'],
            hiddenInput: 'phone',
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.5.3/js/utils.js",
        });
    }

    // Menu
    $(".level_1").has("li.active").addClass("top-active");

    // LightBox
    if ($(".gallery").length) {
        $('.gallery').simpleLightbox();
    }

    // Sliders
    if (document.querySelectorAll('#index-main-slider').length > 0) {
        let swiper = new Swiper("#index-main-slider", {
            slidesPerView: 1,
            autoHeight: true,
            autoplay: {
                delay: 5500,
                disableOnInteraction: false,
            },
            spaceBetween: 30,
            loop: true,
            effect: 'fade',
            speed: 600,
            parallax: true,
            // mousewheel: true,
            breakpoints: {
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
            },
            pagination: {
                el: ".swiper-pagination",
                // dynamicBullets: true,
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }
    if (document.querySelectorAll('#inner-slider-container').length > 0) {
        let serviceSlider = tns({
            container: '#inner-slider',
            controlsContainer: "#inner-slider-control",
            mouseDrag: true,
            autoHeight: false,
            loop: true,
            gutter: 20,
            responsive:
                {
                    "350": {
                        "items": 1,
                    },
                    "760": {
                        "items": 2
                    },
                    "1000": {
                        "items": 3
                    }
                },
            slideBy: 'page',
            autoplay: true,
            controlsPosition: "bottom",
            navPosition: "bottom",
            controls: true,
            autoplayHoverPause: true,
            autoplayButtonOutput: false,
            swipeAngle: false,
            speed: 800
        });
    }

    $(".tableProducts").fancyTable({
        sortColumn:0,
        searchable: true,
        perPage:10,
        globalSearch:true,
        pagination: 'button'
    });

    $.fn.isInViewport = function() {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();
        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop-350 && elementTop-350 < viewportBottom;
    };

    $(window).on('resize scroll', function() {
        $('[data-animation]').each(function(){
            if ($(this).isInViewport()) {
                $(this).addClass($(this).data("animation"));
            }
        })
    });

});
